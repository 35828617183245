import {
  doc,
  DocumentData,
  FirestoreDataConverter,
  getDoc,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore"
import {
  PAYMENT_STATUS_ONSITE,
  PAYMENT_STATUS_PREPAID,
  PAYMENT_STATUS_SUCCESS,
} from "../common/constants/paymentStatuses"
import { db } from "../firebase"
import CheckoutPageData from "../payments/helpers/CheckoutPageData"
import AccessPoint from "./AccessPoint"
import { DateTime } from "luxon"

export type GeocodedAddress = {
  improvedAddress: string
  lat: number
  lng: number
}
export type DeliveryParcel = {
  trackingNumber: string
}

export type DeliveryShipment = {
  shipmentId: string
  externalId: string
  paymentAmount?: number
  paymentStatus: string
  parcels: DeliveryParcel[]
  description: string
  missingDocuments: boolean
  senderName: string
}

type Delivery = {
  id?: string
  objectID?: string // ID from algolia hits
  streetAddress1: string
  streetAddress2?: string
  city: string
  postcode: string
  phoneNumber: string
  email: string
  organisationId?: string
  accessPoint?: AccessPoint
  senderName: string
  receiverName: string
  shipments: DeliveryShipment[]
  deliveryStatus: string
  receiverStatus?: string
  currency?: string
  checkoutPage?: CheckoutPageData
  lmfsTrackingId: string
  geocodedAddress?: GeocodedAddress
  routeBegunAt: string | undefined
}

export default Delivery

export const deliveryConverter: FirestoreDataConverter<Delivery> = {
  toFirestore(delivery: Delivery): DocumentData {
    return delivery
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Delivery {
    const data = snapshot.data(options)
    const delivery: Delivery = {
      id: snapshot.id,
      streetAddress1: data.streetAddress1,
      streetAddress2: data.streetAddress2,
      city: data.city,
      postcode: data.postcode,
      phoneNumber: data.phoneNumber,
      email: data.email,
      organisationId: data.organisationId,
      senderName: data.senderName,
      receiverName: data.receiverName,
      deliveryStatus: data.deliveryStatus,
      accessPoint: data.accessPoint,
      receiverStatus: data.receiverStatus,
      currency: data.currency,
      checkoutPage: data.checkoutPage,
      shipments: data.shipments,
      lmfsTrackingId: data.lmfsTrackingId,
      geocodedAddress: data.geocodedAddress,
      routeBegunAt: data.routeBegunAt,
    }
    return delivery
  },
}

export const getDelivery = async (
  deliveryId: string | undefined
): Promise<Delivery | undefined> => {
  if (!deliveryId) {
    return undefined
  }
  const ref = doc(db, "deliveries", deliveryId).withConverter(deliveryConverter)
  const deliveryDoc = await getDoc(ref)
  return deliveryDoc.data()
}

export const isDeliveryMissingDocuments = (delivery: Delivery | undefined) => {
  if (!delivery) {
    return false
  }

  return delivery.shipments.some((s) => s.missingDocuments)
}

export const isDeliveryShipmentMissingPayment = (
  deliveryShipment: DeliveryShipment
) => {
  return (
    deliveryShipment.paymentStatus !== PAYMENT_STATUS_PREPAID &&
    deliveryShipment.paymentStatus !== PAYMENT_STATUS_SUCCESS &&
    deliveryShipment.paymentStatus !== PAYMENT_STATUS_ONSITE
  )
}

export const isDeliveryMissingPayment = (delivery: Delivery | undefined) => {
  if (!delivery) {
    return false
  }

  return delivery.shipments.some((s) => isDeliveryShipmentMissingPayment(s))
}

export const getPaymentAmountForDelivery = (delivery: Delivery | undefined) => {
  if (!delivery) {
    return 0
  }

  return delivery.shipments.reduce((paymentAmount, deliveryShipment) => {
    if (
      deliveryShipment.paymentAmount &&
      isDeliveryShipmentMissingPayment(deliveryShipment)
    ) {
      return paymentAmount + deliveryShipment.paymentAmount
    }
    return paymentAmount
  }, 0)
}

export const hasRouteBegunToday = (delivery: Delivery | undefined) => {
  const todayMidnight = DateTime.utc().startOf("day").toISO()

  return (
    delivery?.routeBegunAt !== undefined &&
    todayMidnight !== null &&
    delivery.routeBegunAt > todayMidnight
  )
}
